<template>
    <div class="text-component">
        <component :is="layout" :data="data" :config="config" :name="name"></component>
    </div>
</template>

<script>
import first from 'lodash/first'

export default {
    props: {
        data: {
            type: Object,
            default: () => {
            }
        },
        config: {
            type: Object,
            default: () => {
            }
        },
        name: {
            type: String,
            default: ''
        },
    },
    computed: {
        layout() {
            let name = first(this.config?.conf)

            return () => import( `@/components/PageComponents/BrandDescription/${name}` )
        }
    }
}
</script>
